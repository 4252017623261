import Head from 'next/head';

export const availablePages = ['mens', 'womens', 'homepage', 'kids'];

export const Hreflangs = ({ id }: { id?: string }) => {
  if (!id || !availablePages.includes(id)) {
    return null;
  }

  return (
    <>
      {/* This Head has to be here, if it <Head><Hreflangs /></Head> is used then some hreflangs get lost on CSR */}
      <Head>
        {hreflangs[id].map(({ hreflang, href }, index) => (
          <link key={index} rel="alternate" hrefLang={hreflang} href={href} />
        ))}
      </Head>
    </>
  );
};

const hreflangs = {
  mens: [
    { hreflang: 'en-AE', href: 'https://ae.puma.com/mens.html' },
    { hreflang: 'ar-AE', href: 'https://ae.puma.com/ar/mens.html' },
    { hreflang: 'es-AR', href: 'https://ar.puma.com/hombres' },
    { hreflang: 'en-AU', href: 'https://au.puma.com/au/en/men' },
    { hreflang: 'pt-BR', href: 'https://br.puma.com/homens.html' },
    { hreflang: 'en-CA', href: 'https://ca.puma.com/ca/en/men' },
    { hreflang: 'fr-CA', href: 'https://ca.puma.com/ca/fr/men' },
    { hreflang: 'en-CI', href: 'https://ci.puma.com/ci/en/men' },
    { hreflang: 'fr-CI', href: 'https://ci.puma.com/ci/fr/homme' },
    { hreflang: 'es-CL', href: 'https://cl.puma.com/hombres.html' },
    { hreflang: 'en-EG', href: 'https://eg.puma.com/eg/en/men' },
    { hreflang: 'en-DE', href: 'https://eu.puma.com/de/en/men' },
    { hreflang: 'fr-FR', href: 'https://eu.puma.com/fr/fr/homme' },
    { hreflang: 'en-CZ', href: 'https://eu.puma.com/cz/en/men' },
    { hreflang: 'de-DE', href: 'https://eu.puma.com/de/de/herren' },
    { hreflang: 'en-NL', href: 'https://eu.puma.com/nl/en/men' },
    { hreflang: 'en-RO', href: 'https://eu.puma.com/ro/en/men' },
    { hreflang: 'en-NO', href: 'https://eu.puma.com/no/en/men' },
    { hreflang: 'it-IT', href: 'https://eu.puma.com/it/it/uomo' },
    { hreflang: 'en-BG', href: 'https://eu.puma.com/bg/en/men' },
    { hreflang: 'en-PT', href: 'https://eu.puma.com/pt/en/men' },
    { hreflang: 'en-FI', href: 'https://eu.puma.com/fi/en/men' },
    { hreflang: 'pl-PL', href: 'https://eu.puma.com/pl/pl/mezczyzni' },
    { hreflang: 'en-GR', href: 'https://eu.puma.com/gr/en/men' },
    { hreflang: 'fr-CH', href: 'https://eu.puma.com/ch/fr/homme' },
    { hreflang: 'en-SK', href: 'https://eu.puma.com/sk/en/men' },
    { hreflang: 'en-IE', href: 'https://eu.puma.com/ie/en/men' },
    { hreflang: 'de-CH', href: 'https://eu.puma.com/ch/de/herren' },
    { hreflang: 'en-EE', href: 'https://eu.puma.com/ee/en/men' },
    { hreflang: 'en-CH', href: 'https://eu.puma.com/ch/en/men' },
    { hreflang: 'en-HR', href: 'https://eu.puma.com/hr/en/men' },
    { hreflang: 'en-LV', href: 'https://eu.puma.com/lv/en/men' },
    { hreflang: 'en-LU', href: 'https://eu.puma.com/lu/en/men' },
    { hreflang: 'en-LT', href: 'https://eu.puma.com/lt/en/men' },
    { hreflang: 'it-CH', href: 'https://eu.puma.com/ch/it/uomo' },
    { hreflang: 'nl-NL', href: 'https://eu.puma.com/nl/nl/heren' },
    { hreflang: 'en-DK', href: 'https://eu.puma.com/dk/en/men' },
    { hreflang: 'en-HU', href: 'https://eu.puma.com/hu/en/men' },
    { hreflang: 'de-AT', href: 'https://eu.puma.com/at/de/herren' },
    { hreflang: 'nl-BE', href: 'https://eu.puma.com/be/nl/heren' },
    { hreflang: 'es-ES', href: 'https://eu.puma.com/es/es/hombre' },
    { hreflang: 'en-SE', href: 'https://eu.puma.com/se/en/men' },
    { hreflang: 'fr-BE', href: 'https://eu.puma.com/be/fr/homme' },
    { hreflang: 'en-SI', href: 'https://eu.puma.com/si/en/men' },
    { hreflang: 'en-GH', href: 'https://gh.puma.com/gh/en/men' },
    { hreflang: 'zh-HK', href: 'https://hk.puma.com/men/shopall' },
    { hreflang: 'en-HK', href: 'https://hk.puma.com/en/men/shopall' },
    { hreflang: 'id-ID', href: 'https://id.puma.com/in/pria' },
    { hreflang: 'en-ID', href: 'https://id.puma.com/en/men' },
    { hreflang: 'en-IL', href: 'https://il.puma.com/il/en/men' },
    {
      hreflang: 'ar-IL',
      href: 'https://il.puma.com/il/ar/%D8%A7%D9%84%D8%B1%D8%AC%D8%A7%D9%84',
    },
    {
      hreflang: 'he-IL',
      href: 'https://il.puma.com/il/he/%D7%92%D7%91%D7%A8%D7%99%D7%9D',
    },
    { hreflang: 'en-IN', href: 'https://in.puma.com/in/en/mens' },
    {
      hreflang: 'ja-JP',
      href: 'https://jp.puma.com/jp/ja/%E3%83%A1%E3%83%B3%E3%82%BA',
    },
    { hreflang: 'ko-KR', href: 'https://kr.puma.com/kr/ko/%EB%82%A8%EC%84%B1' },
    { hreflang: 'en-MA', href: 'https://ma.puma.com/ma/en/men' },
    {
      hreflang: 'ar-MA',
      href: 'https://ma.puma.com/ma/ar/%D8%A7%D9%84%D8%B1%D8%AC%D8%A7%D9%84',
    },
    { hreflang: 'fr-MA', href: 'https://ma.puma.com/ma/fr/homme' },
    { hreflang: 'es-MX', href: 'https://mx.puma.com/hombres.html' },
    { hreflang: 'en-MX', href: 'https://mx.puma.com/en/mens.html' },
    { hreflang: 'en-MY', href: 'https://my.puma.com/my/en/men' },
    { hreflang: 'en-NG', href: 'https://ng.puma.com/ng/en/men' },
    { hreflang: 'en-NZ', href: 'https://nz.puma.com/nz/en/men' },
    { hreflang: 'es-PE', href: 'https://pe.puma.com/pe/es/hombre' },
    { hreflang: 'en-PH', href: 'https://ph.puma.com/ph/en/men' },
    { hreflang: 'ar-SA', href: 'https://sa.puma.com/mens.html' },
    { hreflang: 'en-SA', href: 'https://sa.puma.com/en/mens.html' },
    { hreflang: 'en-SG', href: 'https://sg.puma.com/sg/en/men' },
    { hreflang: 'en-SN', href: 'https://sn.puma.com/sn/en/men' },
    { hreflang: 'fr-SN', href: 'https://sn.puma.com/sn/fr/homme' },
    {
      hreflang: 'th-TH',
      href: 'https://th.puma.com/th/th/%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B8%8A%E0%B8%B2%E0%B8%A2',
    },
    { hreflang: 'en-TH', href: 'https://th.puma.com/th/en/men' },
    { hreflang: 'en-TN', href: 'https://tn.puma.com/tn/en/men' },
    { hreflang: 'fr-TN', href: 'https://tn.puma.com/tn/fr/homme' },
    {
      hreflang: 'ar-TN',
      href: 'https://tn.puma.com/tn/ar/%D8%A7%D9%84%D8%B1%D8%AC%D8%A7%D9%84',
    },
    { hreflang: 'tr-TR', href: 'https://tr.puma.com/erkek.html' },
    {
      hreflang: 'zh-TW',
      href: 'https://tw.puma.com/v2/official/SalePageCategory/315358',
    },
    {
      hreflang: 'uk-UA',
      href: 'https://ua.puma.com/uk/sportivnye-tovary-dlja-muzhchin.html',
    },
    {
      hreflang: 'ru-UA',
      href: 'https://ua.puma.com/ru/sportivnye-tovary-dlja-muzhchin.html',
    },
    { hreflang: 'en-GB', href: 'https://uk.puma.com/uk/en/men' },
    { hreflang: 'en-US', href: 'https://us.puma.com/us/en/men' },
    { hreflang: 'es-US', href: 'https://us.puma.com/us/es/men' },
    { hreflang: 'en-ZA', href: 'https://za.puma.com/mens' },
  ],
  womens: [
    { hreflang: 'en-AE', href: 'https://ae.puma.com/womens.html' },
    { hreflang: 'ar-AE', href: 'https://ae.puma.com/ar/womens.html' },
    { hreflang: 'es-AR', href: 'https://ar.puma.com/mujeres' },
    { hreflang: 'en-AU', href: 'https://au.puma.com/au/en/women' },
    { hreflang: 'pt-BR', href: 'https://br.puma.com/mulheres.html' },
    { hreflang: 'en-CA', href: 'https://ca.puma.com/ca/en/women' },
    { hreflang: 'fr-CA', href: 'https://ca.puma.com/ca/fr/women' },
    { hreflang: 'en-CI', href: 'https://ci.puma.com/ci/en/women' },
    { hreflang: 'fr-CI', href: 'https://ci.puma.com/ci/fr/femme' },
    { hreflang: 'es-CL', href: 'https://cl.puma.com/mujeres.html' },
    { hreflang: 'en-EG', href: 'https://eg.puma.com/eg/en/women' },
    { hreflang: 'en-DE', href: 'https://eu.puma.com/de/en/women' },
    { hreflang: 'fr-FR', href: 'https://eu.puma.com/fr/fr/femme' },
    { hreflang: 'en-CZ', href: 'https://eu.puma.com/cz/en/women' },
    { hreflang: 'de-DE', href: 'https://eu.puma.com/de/de/damen' },
    { hreflang: 'en-NL', href: 'https://eu.puma.com/nl/en/women' },
    { hreflang: 'en-RO', href: 'https://eu.puma.com/ro/en/women' },
    { hreflang: 'en-NO', href: 'https://eu.puma.com/no/en/women' },
    { hreflang: 'it-IT', href: 'https://eu.puma.com/it/it/donna' },
    { hreflang: 'en-BG', href: 'https://eu.puma.com/bg/en/women' },
    { hreflang: 'en-PT', href: 'https://eu.puma.com/pt/en/women' },
    { hreflang: 'en-FI', href: 'https://eu.puma.com/fi/en/women' },
    { hreflang: 'pl-PL', href: 'https://eu.puma.com/pl/pl/kobiety' },
    { hreflang: 'en-GR', href: 'https://eu.puma.com/gr/en/women' },
    { hreflang: 'fr-CH', href: 'https://eu.puma.com/ch/fr/femme' },
    { hreflang: 'en-SK', href: 'https://eu.puma.com/sk/en/women' },
    { hreflang: 'en-IE', href: 'https://eu.puma.com/ie/en/women' },
    { hreflang: 'de-CH', href: 'https://eu.puma.com/ch/de/damen' },
    { hreflang: 'en-EE', href: 'https://eu.puma.com/ee/en/women' },
    { hreflang: 'en-CH', href: 'https://eu.puma.com/ch/en/women' },
    { hreflang: 'en-HR', href: 'https://eu.puma.com/hr/en/women' },
    { hreflang: 'en-LV', href: 'https://eu.puma.com/lv/en/women' },
    { hreflang: 'en-LU', href: 'https://eu.puma.com/lu/en/women' },
    { hreflang: 'en-LT', href: 'https://eu.puma.com/lt/en/women' },
    { hreflang: 'it-CH', href: 'https://eu.puma.com/ch/it/donna' },
    { hreflang: 'nl-NL', href: 'https://eu.puma.com/nl/nl/dames' },
    { hreflang: 'en-DK', href: 'https://eu.puma.com/dk/en/women' },
    { hreflang: 'en-HU', href: 'https://eu.puma.com/hu/en/women' },
    { hreflang: 'de-AT', href: 'https://eu.puma.com/at/de/damen' },
    { hreflang: 'nl-BE', href: 'https://eu.puma.com/be/nl/dames' },
    { hreflang: 'es-ES', href: 'https://eu.puma.com/es/es/mujer' },
    { hreflang: 'en-SE', href: 'https://eu.puma.com/se/en/women' },
    { hreflang: 'fr-BE', href: 'https://eu.puma.com/be/fr/femme' },
    { hreflang: 'en-SI', href: 'https://eu.puma.com/si/en/women' },
    { hreflang: 'en-GH', href: 'https://gh.puma.com/gh/en/women' },
    { hreflang: 'zh-HK', href: 'https://hk.puma.com/women/shopall' },
    { hreflang: 'en-HK', href: 'https://hk.puma.com/en/women/shopall' },
    { hreflang: 'id-ID', href: 'https://id.puma.com/in/wanita' },
    { hreflang: 'en-ID', href: 'https://id.puma.com/en/women' },
    { hreflang: 'en-IL', href: 'https://il.puma.com/il/en/women' },
    {
      hreflang: 'ar-IL',
      href: 'https://il.puma.com/il/ar/%D8%A7%D9%84%D9%86%D8%B3%D8%A7%D8%A1',
    },
    {
      hreflang: 'he-IL',
      href: 'https://il.puma.com/il/he/%D7%A0%D7%A9%D7%99%D7%9D',
    },
    { hreflang: 'en-IN', href: 'https://in.puma.com/in/en/womens' },
    {
      hreflang: 'ja-JP',
      href: 'https://jp.puma.com/jp/ja/%E3%83%AC%E3%83%87%E3%82%A3%E3%83%BC%E3%82%B9',
    },
    { hreflang: 'ko-KR', href: 'https://kr.puma.com/kr/ko/%EC%97%AC%EC%84%B1' },
    { hreflang: 'en-MA', href: 'https://ma.puma.com/ma/en/women' },
    {
      hreflang: 'ar-MA',
      href: 'https://ma.puma.com/ma/ar/%D8%A7%D9%84%D9%86%D8%B3%D8%A7%D8%A1',
    },
    { hreflang: 'fr-MA', href: 'https://ma.puma.com/ma/fr/femme' },
    { hreflang: 'es-MX', href: 'https://mx.puma.com/mujeres.html' },
    { hreflang: 'en-MX', href: 'https://mx.puma.com/en/womens.html' },
    { hreflang: 'en-MY', href: 'https://my.puma.com/my/en/women' },
    { hreflang: 'en-NG', href: 'https://ng.puma.com/ng/en/women' },
    { hreflang: 'en-NZ', href: 'https://nz.puma.com/nz/en/women' },
    { hreflang: 'es-PE', href: 'https://pe.puma.com/pe/es/mujer' },
    { hreflang: 'en-PH', href: 'https://ph.puma.com/ph/en/women' },
    { hreflang: 'ar-SA', href: 'https://sa.puma.com/womens.html' },
    { hreflang: 'en-SA', href: 'https://sa.puma.com/en/womens.html' },
    { hreflang: 'en-SG', href: 'https://sg.puma.com/sg/en/women' },
    { hreflang: 'en-SN', href: 'https://sn.puma.com/sn/en/women' },
    { hreflang: 'fr-SN', href: 'https://sn.puma.com/sn/fr/femme' },
    {
      hreflang: 'th-TH',
      href: 'https://th.puma.com/th/th/%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B8%AB%E0%B8%8D%E0%B8%B4%E0%B8%87',
    },
    { hreflang: 'en-TH', href: 'https://th.puma.com/th/en/women' },
    { hreflang: 'en-TN', href: 'https://tn.puma.com/tn/en/women' },
    { hreflang: 'fr-TN', href: 'https://tn.puma.com/tn/fr/femme' },
    {
      hreflang: 'ar-TN',
      href: 'https://tn.puma.com/tn/ar/%D8%A7%D9%84%D9%86%D8%B3%D8%A7%D8%A1',
    },
    { hreflang: 'tr-TR', href: 'https://tr.puma.com/kadin.html' },
    {
      hreflang: 'zh-TW',
      href: 'https://tw.puma.com/v2/official/SalePageCategory/314605',
    },
    {
      hreflang: 'uk-UA',
      href: 'https://ua.puma.com/uk/sportivnye-tovary-dlja-zhenshhin.html',
    },
    {
      hreflang: 'ru-UA',
      href: 'https://ua.puma.com/ru/sportivnye-tovary-dlja-zhenshhin.html',
    },
    { hreflang: 'en-GB', href: 'https://uk.puma.com/uk/en/women' },
    { hreflang: 'en-US', href: 'https://us.puma.com/us/en/women' },
    { hreflang: 'es-US', href: 'https://us.puma.com/us/es/women' },
    { hreflang: 'en-ZA', href: 'https://za.puma.com/womens' },
  ],
  homepage: [
    { hreflang: 'en-AE', href: 'https://ae.puma.com/' },
    { hreflang: 'ar-AE', href: 'https://ae.puma.com/ar/' },
    { hreflang: 'es-AR', href: 'https://ar.puma.com/' },
    { hreflang: 'en-AU', href: 'https://au.puma.com/' },
    { hreflang: 'pt-BR', href: 'https://br.puma.com/' },
    { hreflang: 'en-CA', href: 'https://ca.puma.com/ca/en' },
    { hreflang: 'fr-CA', href: 'https://ca.puma.com/ca/fr' },
    { hreflang: 'en-CI', href: 'https://ci.puma.com/ci/en/home' },
    { hreflang: 'fr-CI', href: 'https://ci.puma.com/ci/fr/home' },
    { hreflang: 'es-CL', href: 'https://cl.puma.com/' },
    { hreflang: 'en-EG', href: 'https://eg.puma.com/eg/en/home' },
    { hreflang: 'en-DE', href: 'https://eu.puma.com/de/en/home' },
    { hreflang: 'fr-FR', href: 'https://eu.puma.com/fr/fr/home' },
    { hreflang: 'en-CZ', href: 'https://eu.puma.com/cz/en/home' },
    { hreflang: 'de-DE', href: 'https://eu.puma.com/de/de/home' },
    { hreflang: 'en-NL', href: 'https://eu.puma.com/nl/en/home' },
    { hreflang: 'en-RO', href: 'https://eu.puma.com/ro/en/home' },
    { hreflang: 'en-NO', href: 'https://eu.puma.com/no/en/home' },
    { hreflang: 'it-IT', href: 'https://eu.puma.com/it/it/home' },
    { hreflang: 'en-BG', href: 'https://eu.puma.com/bg/en/home' },
    { hreflang: 'en-PT', href: 'https://eu.puma.com/pt/en/home' },
    { hreflang: 'en-FI', href: 'https://eu.puma.com/fi/en/home' },
    { hreflang: 'pl-PL', href: 'https://eu.puma.com/pl/pl/home' },
    { hreflang: 'en-GR', href: 'https://eu.puma.com/gr/en/home' },
    { hreflang: 'fr-CH', href: 'https://eu.puma.com/ch/fr/home' },
    { hreflang: 'en-SK', href: 'https://eu.puma.com/sk/en/home' },
    { hreflang: 'en-IE', href: 'https://eu.puma.com/ie/en/home' },
    { hreflang: 'de-CH', href: 'https://eu.puma.com/ch/de/home' },
    { hreflang: 'en-EE', href: 'https://eu.puma.com/ee/en/home' },
    { hreflang: 'en-CH', href: 'https://eu.puma.com/ch/en/home' },
    { hreflang: 'en-HR', href: 'https://eu.puma.com/hr/en/home' },
    { hreflang: 'en-LV', href: 'https://eu.puma.com/lv/en/home' },
    { hreflang: 'en-LU', href: 'https://eu.puma.com/lu/en/home' },
    { hreflang: 'en-LT', href: 'https://eu.puma.com/lt/en/home' },
    { hreflang: 'it-CH', href: 'https://eu.puma.com/ch/it/home' },
    { hreflang: 'nl-NL', href: 'https://eu.puma.com/nl/nl/home' },
    { hreflang: 'en-DK', href: 'https://eu.puma.com/dk/en/home' },
    { hreflang: 'en-HU', href: 'https://eu.puma.com/hu/en/home' },
    { hreflang: 'de-AT', href: 'https://eu.puma.com/at/de/home' },
    { hreflang: 'nl-BE', href: 'https://eu.puma.com/be/nl/home' },
    { hreflang: 'es-ES', href: 'https://eu.puma.com/es/es/home' },
    { hreflang: 'en-SE', href: 'https://eu.puma.com/se/en/home' },
    { hreflang: 'fr-BE', href: 'https://eu.puma.com/be/fr/home' },
    { hreflang: 'en-SI', href: 'https://eu.puma.com/si/en/home' },
    { hreflang: 'en-GH', href: 'https://gh.puma.com/gh/en/home' },
    { hreflang: 'zh-HK', href: 'https://hk.puma.com/' },
    { hreflang: 'en-HK', href: 'https://hk.puma.com/en/' },
    { hreflang: 'id-ID', href: 'https://id.puma.com/in/home' },
    { hreflang: 'en-ID', href: 'https://id.puma.com/en/home' },
    { hreflang: 'en-IL', href: 'https://il.puma.com/il/en/home' },
    { hreflang: 'ar-IL', href: 'https://il.puma.com/il/ar/home' },
    { hreflang: 'he-IL', href: 'https://il.puma.com/il/he/home' },
    { hreflang: 'en-IN', href: 'https://in.puma.com/in/en' },
    { hreflang: 'ja-JP', href: 'https://jp.puma.com/jp/ja' },
    { hreflang: 'ko-KR', href: 'https://kr.puma.com/kr/ko/home' },
    { hreflang: 'en-MA', href: 'https://ma.puma.com/ma/en/home' },
    { hreflang: 'ar-MA', href: 'https://ma.puma.com/ma/ar/home' },
    { hreflang: 'fr-MA', href: 'https://ma.puma.com/ma/fr/home' },
    { hreflang: 'es-MX', href: 'https://mx.puma.com/' },
    { hreflang: 'en-MX', href: 'https://mx.puma.com/en' },
    { hreflang: 'en-MY', href: 'https://my.puma.com/' },
    { hreflang: 'en-NG', href: 'https://ng.puma.com/ng/en/home' },
    { hreflang: 'en-NZ', href: 'https://nz.puma.com/' },
    { hreflang: 'es-PE', href: 'https://pe.puma.com/pe/es/home' },
    { hreflang: 'en-PH', href: 'https://ph.puma.com/ph/en/home' },
    { hreflang: 'ar-SA', href: 'https://sa.puma.com/' },
    { hreflang: 'en-SA', href: 'https://sa.puma.com/en/' },
    { hreflang: 'en-SG', href: 'https://sg.puma.com/' },
    { hreflang: 'en-SN', href: 'https://sn.puma.com/sn/en/home' },
    { hreflang: 'fr-SN', href: 'https://sn.puma.com/sn/fr/home' },
    { hreflang: 'th-TH', href: 'https://th.puma.com/' },
    { hreflang: 'en-TH', href: 'https://th.puma.com/th/en/home' },
    { hreflang: 'en-TN', href: 'https://tn.puma.com/tn/en/home' },
    { hreflang: 'fr-TN', href: 'https://tn.puma.com/tn/fr/home' },
    { hreflang: 'ar-TN', href: 'https://tn.puma.com/tn/ar/home' },
    { hreflang: 'tr-TR', href: 'https://tr.puma.com/' },
    { hreflang: 'zh-TW', href: 'https://tw.puma.com/' },
    { hreflang: 'uk-UA', href: 'https://ua.puma.com/uk/' },
    { hreflang: 'ru-UA', href: 'https://ua.puma.com/ru/' },
    { hreflang: 'en-GB', href: 'https://uk.puma.com/uk/en' },
    { hreflang: 'en-US', href: 'https://us.puma.com/us/en' },
    { hreflang: 'es-US', href: 'https://us.puma.com/us/es' },
    { hreflang: 'en-ZA', href: 'https://za.puma.com/' },
  ],
  kids: [
    { hreflang: 'en-AE', href: 'https://ae.puma.com/kids.html' },
    { hreflang: 'ar-AE', href: 'https://ae.puma.com/ar/kids.html' },
    { hreflang: 'es-AR', href: 'https://ar.puma.com/ninos' },
    { hreflang: 'en-AU', href: 'https://au.puma.com/au/en/kids' },
    { hreflang: 'pt-BR', href: 'https://br.puma.com/kids.html' },
    { hreflang: 'en-CA', href: 'https://ca.puma.com/ca/en/kids' },
    { hreflang: 'fr-CA', href: 'https://ca.puma.com/ca/fr/kids' },
    { hreflang: 'en-CI', href: 'https://ci.puma.com/ci/en/kids' },
    { hreflang: 'fr-CI', href: 'https://ci.puma.com/ci/fr/enfant' },
    { hreflang: 'es-CL', href: 'https://cl.puma.com/ni-os.html' },
    { hreflang: 'en-EG', href: 'https://eg.puma.com/eg/en/kids' },
    { hreflang: 'en-DE', href: 'https://eu.puma.com/de/en/kids' },
    { hreflang: 'fr-FR', href: 'https://eu.puma.com/fr/fr/enfant' },
    { hreflang: 'en-CZ', href: 'https://eu.puma.com/cz/en/kids' },
    { hreflang: 'de-DE', href: 'https://eu.puma.com/de/de/kinder' },
    { hreflang: 'en-NL', href: 'https://eu.puma.com/nl/en/kids' },
    { hreflang: 'en-RO', href: 'https://eu.puma.com/ro/en/kids' },
    { hreflang: 'en-NO', href: 'https://eu.puma.com/no/en/kids' },
    { hreflang: 'it-IT', href: 'https://eu.puma.com/it/it/bambino' },
    { hreflang: 'en-BG', href: 'https://eu.puma.com/bg/en/kids' },
    { hreflang: 'en-PT', href: 'https://eu.puma.com/pt/en/kids' },
    { hreflang: 'en-FI', href: 'https://eu.puma.com/fi/en/kids' },
    { hreflang: 'pl-PL', href: 'https://eu.puma.com/pl/pl/dzieciece' },
    { hreflang: 'en-GR', href: 'https://eu.puma.com/gr/en/kids' },
    { hreflang: 'fr-CH', href: 'https://eu.puma.com/ch/fr/enfant' },
    { hreflang: 'en-SK', href: 'https://eu.puma.com/sk/en/kids' },
    { hreflang: 'en-IE', href: 'https://eu.puma.com/ie/en/kids' },
    { hreflang: 'de-CH', href: 'https://eu.puma.com/ch/de/kinder' },
    { hreflang: 'en-EE', href: 'https://eu.puma.com/ee/en/kids' },
    { hreflang: 'en-CH', href: 'https://eu.puma.com/ch/en/kids' },
    { hreflang: 'en-HR', href: 'https://eu.puma.com/hr/en/kids' },
    { hreflang: 'en-LV', href: 'https://eu.puma.com/lv/en/kids' },
    { hreflang: 'en-LU', href: 'https://eu.puma.com/lu/en/kids' },
    { hreflang: 'en-LT', href: 'https://eu.puma.com/lt/en/kids' },
    { hreflang: 'it-CH', href: 'https://eu.puma.com/ch/it/bambino' },
    { hreflang: 'nl-NL', href: 'https://eu.puma.com/nl/nl/kinderen' },
    { hreflang: 'en-DK', href: 'https://eu.puma.com/dk/en/kids' },
    { hreflang: 'en-HU', href: 'https://eu.puma.com/hu/en/kids' },
    { hreflang: 'de-AT', href: 'https://eu.puma.com/at/de/kinder' },
    { hreflang: 'nl-BE', href: 'https://eu.puma.com/be/nl/kinderen' },
    { hreflang: 'es-ES', href: 'https://eu.puma.com/es/es/nino' },
    { hreflang: 'en-SE', href: 'https://eu.puma.com/se/en/kids' },
    { hreflang: 'fr-BE', href: 'https://eu.puma.com/be/fr/enfant' },
    { hreflang: 'en-SI', href: 'https://eu.puma.com/si/en/kids' },
    { hreflang: 'en-GH', href: 'https://gh.puma.com/gh/en/kids' },
    { hreflang: 'zh-HK', href: 'https://hk.puma.com/kids/shopall' },
    { hreflang: 'en-HK', href: 'https://hk.puma.com/en/kids/shopall' },
    { hreflang: 'id-ID', href: 'https://id.puma.com/in/anak-anak' },
    { hreflang: 'en-ID', href: 'https://id.puma.com/en/kids' },
    { hreflang: 'en-IL', href: 'https://il.puma.com/il/en/kids' },
    {
      hreflang: 'ar-IL',
      href: 'https://il.puma.com/il/ar/%D8%A7%D9%84%D8%A3%D8%B7%D9%81%D8%A7%D9%84',
    },
    {
      hreflang: 'he-IL',
      href: 'https://il.puma.com/il/he/%D7%99%D7%9C%D7%93%D7%99%D7%9D',
    },
    { hreflang: 'en-IN', href: 'https://in.puma.com/in/en/kids' },
    {
      hreflang: 'ja-JP',
      href: 'https://jp.puma.com/jp/ja/%E3%82%AD%E3%83%83%E3%82%BA',
    },
    { hreflang: 'ko-KR', href: 'https://kr.puma.com/kr/ko/kids' },
    { hreflang: 'en-MA', href: 'https://ma.puma.com/ma/en/kids' },
    {
      hreflang: 'ar-MA',
      href: 'https://ma.puma.com/ma/ar/%D8%A7%D9%84%D8%A3%D8%B7%D9%81%D8%A7%D9%84',
    },
    { hreflang: 'fr-MA', href: 'https://ma.puma.com/ma/fr/enfant' },
    { hreflang: 'es-MX', href: 'https://mx.puma.com/minicats' },
    { hreflang: 'en-MX', href: 'https://mx.puma.com/en/kids.html' },
    { hreflang: 'en-MY', href: 'https://my.puma.com/my/en/kids' },
    { hreflang: 'en-NG', href: 'https://ng.puma.com/ng/en/kids' },
    { hreflang: 'en-NZ', href: 'https://nz.puma.com/nz/en/kids' },
    { hreflang: 'es-PE', href: 'https://pe.puma.com/pe/es/ninos' },
    { hreflang: 'en-PH', href: 'https://ph.puma.com/ph/en/kids' },
    { hreflang: 'ar-SA', href: 'https://sa.puma.com/kids.html' },
    { hreflang: 'en-SA', href: 'https://sa.puma.com/en/kids.html' },
    { hreflang: 'en-SG', href: 'https://sg.puma.com/sg/en/kids' },
    { hreflang: 'en-SN', href: 'https://sn.puma.com/sn/en/kids' },
    { hreflang: 'fr-SN', href: 'https://sn.puma.com/sn/fr/enfant' },
    {
      hreflang: 'th-TH',
      href: 'https://th.puma.com/th/th/%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%81',
    },
    { hreflang: 'en-TH', href: 'https://th.puma.com/th/en/kids' },
    { hreflang: 'en-TN', href: 'https://tn.puma.com/tn/en/kids' },
    { hreflang: 'fr-TN', href: 'https://tn.puma.com/tn/fr/enfant' },
    {
      hreflang: 'ar-TN',
      href: 'https://tn.puma.com/tn/ar/%D8%A7%D9%84%D8%A3%D8%B7%D9%81%D8%A7%D9%84',
    },
    { hreflang: 'tr-TR', href: 'https://tr.puma.com/cocuk.html' },
    {
      hreflang: 'zh-TW',
      href: 'https://tw.puma.com/v2/official/SalePageCategory/315360',
    },
    {
      hreflang: 'uk-UA',
      href: 'https://ua.puma.com/uk/sportivnye-tovary-dlja-detej.html',
    },
    {
      hreflang: 'ru-UA',
      href: 'https://ua.puma.com/ru/sportivnye-tovary-dlja-detej.html',
    },
    { hreflang: 'en-GB', href: 'https://uk.puma.com/uk/en/kids' },
    { hreflang: 'en-US', href: 'https://us.puma.com/us/en/kids' },
    { hreflang: 'es-US', href: 'https://us.puma.com/us/es/kids' },
    { hreflang: 'en-ZA', href: 'https://za.puma.com/kids' },
  ],
};
