import { tw } from 'twind/css';
import { ButtonIcon, Icon, Text } from '@global-ecom/nitro-uds/elements';

import { AuthAction, AuthenticateForm } from 'ui/forms/AuthenticateForm';
import { useFeature } from 'hooks/useFeature';
import { useMainNavContext } from 'hooks/useMainNav';

type PopoverFormProps = {
  action: AuthAction;
  isOpen: boolean;
  isMobile?: boolean;
  buttonText: string;
  handleClick: () => void;
  onClose: () => void;
};

export const PopoverForm = (props: PopoverFormProps) => {
  const { action, isOpen, isMobile, buttonText, handleClick, onClose } = props;
  const popoverLoginEnabledJP = useFeature('POPOVER_LOGIN_ENABLED_JP');
  const { isRegisterOpen } = useMainNavContext();
  const { isForgotPasswordFormOpen } = useMainNavContext();

  return isMobile ? (
    <div
      className={tw(
        'absolute top-0 bg-white w-full transform transition-all duration-500 overflow-y-hidden',
        {
          'h-screen': !isRegisterOpen || !popoverLoginEnabledJP,
          'translate-x-full invisible': !isOpen,
          'translate-x-0': isOpen,
          'z-50': action === AuthAction.reset_password,
          'z-30': action !== AuthAction.reset_password,
        }
      )}
    >
      <div className="bg-puma-black-400 bg-opacity-10 p-4 flex justify-between text-xl capitalize">
        <button
          data-test-id="mobile-nav-prev-icon"
          data-link-name="go-back"
          data-link-location="mobile-nav"
          onClick={handleClick}
          type="button"
        >
          <span className="text-puma-black-200">
            <Icon name="chevron-left" size="2xl" />
          </span>
        </button>
        <h2>{buttonText}</h2>
        <span className="w-8"></span>
      </div>
      <div
        className={tw('mx-auto max-w-screen-xs px-3 pt-8', {
          'pb-16': popoverLoginEnabledJP,
        })}
      >
        <AuthenticateForm
          isInPopover={true}
          onClose={() => onClose()}
          action={action}
        />
      </div>
    </div>
  ) : (
    <div
      className={tw(
        'overflow-y-auto max-h-[calc(100vh-150px)] p-4 fixed bg-white z-10 top-0 right-0 w-full',
        {
          'transform transition-all duration-300': !isForgotPasswordFormOpen,
          'opacity-0 invisible': !isOpen,
          'border 0px solid rgb(229) opacity-100': isOpen,
        }
      )}
    >
      <div className="w-full flex justify-center pb-5">
        <ButtonIcon
          label="chevron"
          icon="chevron-left"
          invert
          onClick={handleClick}
          className="m-4 absolute top-0 left-0"
        />
        <Text className="mt-1 text-xl"> {buttonText}</Text>
      </div>
      <div className="pt-5 pb-3">
        <AuthenticateForm
          isInPopover={true}
          onClose={() => onClose()}
          action={action}
        />
      </div>
    </div>
  );
};
