import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { usePincodeValidation, useValidation } from 'hooks/useValidation';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { CustomPincode, SubmitFunction } from 'ui/forms/custom/types';
import { transformPlaces } from 'utils/transformPlaces';
import { buildName } from 'ui/forms/custom/helpers';
import { AlertMessage } from 'ui/elements/AlertMessage';
import { useFeature } from 'hooks/useFeature';
import { useGoogleServices } from 'hooks/useGoogleServices';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { extractError } from 'utils/extractError';
import { Maybe } from '__generated__/graphql';

const CustomFormPinCodeAddressLookUp = ({
  field,
  prefix,
  submit,
}: {
  field: CustomPincode;
  country: string;
  prefix?: string;
  submit?: SubmitFunction;
}) => {
  const t = useTranslate();
  const [validate, dynamicValidation] = useValidation();
  const form = useFormContext();
  const { resolveInputError } = useFormField(field);

  const [pincodeQuery, validatePincode] = usePincodeValidation();

  const { register, watch } = form;

  const isGoogleReversePostalCodeLookupEnabled = useFeature(
    'GOOGLE_REVERSE_POSTAL_CODE_LOOKUP'
  );

  /** Sets the values for the fields given, then triggers validation on these
   * fields. Will not set or trigger on fields where the value is nullish.
   *
   * Note: Field order matters!
   */
  const setValueAndTrigger = (fields: {
    [key: string]: Maybe<string> | undefined;
  }) => {
    const affectedFields: string[] = [];

    Object.entries(fields).forEach(([key, value]) => {
      if (value) {
        form.setValue(buildName(key, prefix), value);
        affectedFields.push(buildName(key, prefix));
      }
    });

    form.trigger();
  };

  const errors = prefix && form.errors[prefix];

  const isPincodeEnabled = useFeature('PINCODE_SERVICEABILITY');

  const expectedDeliveryDate =
    !errors?.postalCode?.message &&
    !pincodeQuery.fetching &&
    pincodeQuery.data?.pincode?.expectedDate;

  const { countryCode: siteConfigCountryCode } = useSiteConfig();

  const { getPlaceDetails, geocode } = useGoogleServices();

  const currentCountryCode = siteConfigCountryCode.toUpperCase();

  const googleReversePostalCodeLookup = async (postalCode: string) => {
    try {
      const address = await geocode(postalCode, currentCountryCode);
      const placeDetails = await getPlaceDetails(address.place_id);

      const { city, address1, stateCode } = transformPlaces(placeDetails);

      autofillCityAndState({ city, address1, stateCode });
    } catch {}
  };

  const autofillAddressByPostalCode = async (postalCode: string) => {
    // Manually re-run validation
    const postalCodeError = validate.postalCode(postalCode, currentCountryCode);
    if (postalCodeError) return;

    if (isPincodeEnabled) {
      const { data } = (await validatePincode(postalCode)) ?? {};

      if (data?.pincode.stateCode) {
        const { city, stateCode } = data.pincode;
        autofillCityAndState({ city, stateCode });
        return;
      }
    }

    await googleReversePostalCodeLookup(postalCode);
  };

  const autofillCityAndState = ({
    city,
    address1,
    stateCode,
  }: {
    city?: Maybe<string>;
    address1?: Maybe<string>;
    stateCode?: Maybe<string>;
  }) => setValueAndTrigger({ city, address1, stateCode });

  const watchedStateCode = watch(buildName('stateCode', prefix));

  return (
    <div className="space-y-2">
      <InputText
        defaultValue={watch(field.name)}
        key={field.key}
        name={field.name}
        label={field.label}
        id={field.id}
        dataTestId={field.dataTestId}
        placeholder={field.placeholder || ''}
        maxLength={field.maxLength}
        required
        onChange={async e => {
          if (isGoogleReversePostalCodeLookupEnabled) {
            await autofillAddressByPostalCode(e.target.value);
          } else {
            watchedStateCode && form.trigger(buildName('stateCode', prefix));
          }

          if (!errors?.postalCode && submit) {
            submit();
          }
        }}
        ref={register({
          required: t('requiredField'),
          validate: async value => {
            let errorMessage: string | undefined;
            if (field.validations) {
              for (const v of field.validations) {
                if (typeof v === 'string') {
                  errorMessage = validate[v](value);
                } else {
                  errorMessage = dynamicValidation(value, form, v);
                }
                if (errorMessage) break;
              }
            }

            if (errorMessage) return errorMessage;

            const res = await validatePincode(value);

            if (!res) return;

            const error = extractError(res);

            if (error) return error;
          },
          maxLength: field.maxLength && {
            value: field.maxLength,
            message: t<'genericFieldMaxLength'>('genericFieldMaxLength', {
              length: field.maxLength,
            }),
          },
          minLength: field.minLength && {
            value: field.minLength,
            message: t<'genericFieldMinLength'>('genericFieldMinLength', {
              length: field.minLength,
            }),
          },
        })}
        errorText={resolveInputError()}
        crossOrigin={undefined}
      />
      {prefix === 'shippingAddress' && (
        <>
          {pincodeQuery.fetching && (
            <AlertMessage
              icon="truck"
              dataTestId="pincode-query-fetching"
              type="info"
            >
              {t('loadingDotDotDot')}
            </AlertMessage>
          )}
          {expectedDeliveryDate && (
            <AlertMessage
              icon="truck"
              dataTestId="expected-delivery-date"
              type="success"
            >
              {expectedDeliveryDate}
            </AlertMessage>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(CustomFormPinCodeAddressLookUp);
