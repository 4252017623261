import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { UseQueryState } from 'urql';

import {
  Variant,
  usePixleeConfigQuery,
  useTrackProductViewMutation,
  useProductScoreQuery,
  Score,
  useUserGeneratedContentQuery,
  QueryContentArgs,
  usePdpQuery,
  useLazyPdpQuery,
} from '__generated__/graphql';
import type { PixleeImgType } from 'ui/pages/ProductDetailsPages/types';
import { REFERRER_CATEGORY, isServer } from 'utils/constants';
import {
  ProductTypeFromPDPQuery,
  ProductQuery,
  LazyProductQuery,
  ExtraProductTypeFromLazyPDPQuery,
} from 'types/product';
import {
  ProductDetailsPageContent,
  AboveProductStory_PDPContentQuery,
  BelowProductGoogleShopping_PDPContentQuery,
  BelowProductStory_PDPContentQuery,
  ExtendedData_PDPContentQuery,
  ProductStoryAdditionalContentSlot_PDPContentQuery,
  AboveProductStory_PDPContent,
  ProductStoryAdditionalContentSlot_PDPContent,
  BelowProductStory_PDPContent,
  BelowProductGoogleShopping_PDPContent,
  ExtendedData_PDPContent,
} from 'groq/pages/ProductDetailsPage';
import {
  ProductDetailsPageTemplateContent,
  AboveProductStory_PDPTemplateQuery,
  BelowProductStory_PDPTemplateQuery,
  BelowProductGoogleShopping_PDPTemplateQuery,
  StickyAddToCart_PDPTemplateQuery,
  ExtendedData_PDPTemplateQuery,
  AboveProductStory_PDPTemplateContent,
  BelowProductStory_PDPTemplateContent,
  BelowProductGoogleShopping_PDPTemplateContent,
  StickyAddToCart_PDPTemplateContent,
  ExtendedData_PDPTemplateContent,
} from 'groq/pages/ProductDetailsPageTemplate';
import {
  getGa4Item,
  removeGa4ItemFromMap,
  setGa4ItemInMap,
} from 'utils/ga4Items';

import { useFeature } from './useFeature';
import { ContentResponse, useGroqQuery } from './useGroqQuery';
import { useEmarsysWebExtend } from './useEmarsysWebExtend';
import { useIsCrawler } from './useIsCrawler';

const isGoogleShoppingLink = (url: string): boolean => url.includes('gclid');

type PageLayout =
  | 'full'
  | 'simplified'
  | 'soldoutWithRecommender'
  | 'noVariant';

type ProductDivision = 'Footwear' | 'Other';

type PdpContextType = {
  addToCartError: string | undefined;
  addToCartReturnCode: string | undefined;
  desiredProductId: string;
  desiredModel: string;
  desiredSize: string | undefined;
  desiredSwatch: string | undefined;
  hasLoaded: boolean;
  isAppOnly: boolean;
  isFetching: boolean;
  isFullLayout: boolean;
  isQueryStale: boolean;
  isSimplifiedLayout: boolean;
  masterProduct: ProductTypeFromPDPQuery & ExtraProductTypeFromLazyPDPQuery;
  pageLayout: PageLayout;
  pixleeImgs?: PixleeImgType[];
  productIsOrderable: boolean;
  productDivision: ProductDivision;
  productQuery: ProductQuery;
  lazyProductQuery: LazyProductQuery;
  quantity: number;
  referrerCategoryId?: string;
  setAddToCartError: (s: string | undefined) => void;
  setAddToCartReturnCode: (s: string | undefined) => void;
  setQuantity: (n: number) => void;
  variation: Variant | undefined | null;
  content:
    | (ProductDetailsPageContent & ProductDetailsPageTemplateContent)
    | undefined
    | null;
  productScore: Score | undefined;
  sizeHasChanged: boolean;
  setSizeHasChanged: (boolean) => void;

  // Content PDP Query Types
  aboveProductStoryContent: UseQueryState<
    ContentResponse<AboveProductStory_PDPContent>,
    QueryContentArgs
  >;
  productStoryAdditionalContentSlotContent: UseQueryState<
    ContentResponse<ProductStoryAdditionalContentSlot_PDPContent>,
    QueryContentArgs
  >;
  belowProductStoryContent: UseQueryState<
    ContentResponse<BelowProductStory_PDPContent>,
    QueryContentArgs
  >;
  belowProductGoogleShoppingContent: UseQueryState<
    ContentResponse<BelowProductGoogleShopping_PDPContent>,
    QueryContentArgs
  >;
  extendedDataContent: UseQueryState<
    ContentResponse<ExtendedData_PDPContent>,
    QueryContentArgs
  >;

  // Content PDP Template Query Types
  aboveProductStoryTemplateContent: UseQueryState<
    ContentResponse<AboveProductStory_PDPTemplateContent>,
    QueryContentArgs
  >;
  belowProductStoryTemplateContent: UseQueryState<
    ContentResponse<BelowProductStory_PDPTemplateContent>,
    QueryContentArgs
  >;
  belowProductGoogleShoppingTemplateContent: UseQueryState<
    ContentResponse<BelowProductGoogleShopping_PDPTemplateContent>,
    QueryContentArgs
  >;
  stickyAddToCartTemplateContent: UseQueryState<
    ContentResponse<StickyAddToCart_PDPTemplateContent>,
    QueryContentArgs
  >;
  extendedDataTemplateContent: UseQueryState<
    ContentResponse<ExtendedData_PDPTemplateContent>,
    QueryContentArgs
  >;
};

const PdpContext = createContext<PdpContextType>(null as any);

export const PdpProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const hasSentAnalytics = useRef(false);
  const [, trackProductView] = useTrackProductViewMutation();
  const reviewsEnabled = useFeature('RATINGS_AND_REVIEWS');
  const isCrawler = useIsCrawler();

  const [addToCartError, setAddToCartError] = useState<string>();
  const [addToCartReturnCode, setAddToCartReturnCode] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1);

  const [sizeHasChanged, setSizeHasChanged] = useState(false);
  const desiredProductId = router.query.id as string;
  const desiredModel = router.query.model as string;

  const [pixleeConfigResponse] = usePixleeConfigQuery();
  const pixleeConfig = pixleeConfigResponse.data?.pixleeConfig;

  const { trackPdp } = useEmarsysWebExtend();

  const [productQuery, refetch] = usePdpQuery({
    variables: {
      id: desiredProductId,
    },
    pause: !desiredProductId,
    requestPolicy: 'cache-first',
  });

  /**
   * This new query in the usePdp hook was named as “lazy” because it was created to be executed only on the client side.
   * This query fetches the data set that was split from the pdpQuery or productQuery in this branch.
   * We split the data to only require on the server side the main data for the first load, and the rest of the data, only on the client side, to reduce server execution times.
   * These changes only apply to users, not bots/crawlers so as not to affect any SEO relevant data, and the new pause condition reflects this.
   */
  const [lazyProductQuery] = useLazyPdpQuery({
    variables: {
      id: desiredProductId,
    },
    pause:
      !desiredProductId ||
      (isServer && !isCrawler) ||
      (!isCrawler &&
        (productQuery.fetching ||
          typeof productQuery.fetching === 'undefined' ||
          !productQuery.data?.product)),
    requestPolicy: 'cache-first',
  });

  useEffect(() => {
    refetch({ requestPolicy: 'cache-and-network' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const masterProduct = productQuery.data?.product as ProductTypeFromPDPQuery &
    ExtraProductTypeFromLazyPDPQuery;
  const isFetching = productQuery.fetching;
  const isQueryStale = productQuery.stale;

  if (lazyProductQuery.data && !lazyProductQuery.fetching) {
    masterProduct?.variations.forEach(variation => {
      const lazyProductData = lazyProductQuery.data?.product?.variations.find(
        lazyVariation => variation.id === lazyVariation.id
      );

      if (lazyProductData) {
        variation['description'] = lazyProductData.description;
        variation['productStory'] = lazyProductData.productStory;
        variation['sizeGroups'] = lazyProductData.sizeGroups;
      }
    });
  }

  const desiredSwatch =
    (router.query.swatch as string | undefined) ||
    masterProduct?.variations[0].colorValue;

  const hasDesiredProductIdAndSwatch = Boolean(
    desiredProductId && desiredSwatch
  );

  // Updates the variation when desiredSwatch changes
  const variation = useMemo(() => {
    if (isFetching && !isQueryStale) return undefined;
    const variations = (masterProduct?.variations || []) as Variant[];
    if (!masterProduct || variations.length === 0) {
      if (hasDesiredProductIdAndSwatch)
        removeGa4ItemFromMap(`${desiredProductId}_${desiredSwatch}`);
      return undefined;
    }

    // return the requested swatch, if available
    if (desiredSwatch) {
      const variation = variations.find(x => x.colorValue === desiredSwatch);
      if (variation) return variation;
    }

    // if no swatch was requested (url params) or found, try to return the first orderable swatch
    // if no orderable swatches return the first swatch
    const foundVariation = variations.find(v => v.orderable) || variations[0];
    if (foundVariation) {
      const oldGa4Item =
        hasDesiredProductIdAndSwatch &&
        getGa4Item(`${desiredProductId}_${desiredSwatch}`);
      if (oldGa4Item)
        setGa4ItemInMap(`${desiredProductId}_${foundVariation.colorValue}`, {
          lid: oldGa4Item?.lid,
          lname: oldGa4Item?.lname,
          idx: oldGa4Item?.idx,
          cname: oldGa4Item?.cname,
          cslot: oldGa4Item?.cslot,
          pid: oldGa4Item?.pid,
          pname: oldGa4Item?.pname,
        });
    }
    if (hasDesiredProductIdAndSwatch)
      removeGa4ItemFromMap(`${desiredProductId}_${desiredSwatch}`);
    return foundVariation;
  }, [
    isQueryStale,
    isFetching,
    desiredProductId,
    desiredSwatch,
    masterProduct,
    hasDesiredProductIdAndSwatch,
  ]);

  const desiredSize = router.query.size as string | undefined;

  // Feature flag to allow simplified view for urls with Google Shopping indicator
  // in them ('&gclid=someValue')
  const enableGoogleShopping = useFeature(
    'SHOW_SIMPLIFIED_PDP_FOR_GOOGLE_SHOPPING'
  );

  const pageLayout: PageLayout = useMemo(() => {
    if (enableGoogleShopping && isGoogleShoppingLink(router.asPath)) {
      return 'simplified';
    }

    if (masterProduct?.displayOutOfStock?.soldoutWithRecommender) {
      return 'soldoutWithRecommender';
    }

    if (!variation) return 'noVariant';

    return 'full';
  }, [variation, masterProduct, enableGoogleShopping, router.asPath]);

  const referrerCategoryId = useRef<string>();

  if (!isServer) {
    referrerCategoryId.current =
      window.localStorage.getItem(REFERRER_CATEGORY) ?? undefined;
  }

  // Content PDP Template Queries
  const [aboveProductStoryTemplateContent] = useGroqQuery({
    operationName: 'AboveProductStory_PDPTemplate',
    query: AboveProductStory_PDPTemplateQuery,
  });

  const [belowProductStoryTemplateContent] = useGroqQuery({
    operationName: 'BelowProductStory_PDPTemplate',
    query: BelowProductStory_PDPTemplateQuery,
  });

  const [belowProductGoogleShoppingTemplateContent] = useGroqQuery({
    operationName: 'BelowProductGoogleShopping_PDPTemplate',
    query: BelowProductGoogleShopping_PDPTemplateQuery,
  });

  const [stickyAddToCartTemplateContent] = useGroqQuery({
    operationName: 'StickyAddToCart_PDPTemplate',
    query: StickyAddToCart_PDPTemplateQuery,
  });

  const [extendedDataTemplateContent] = useGroqQuery({
    operationName: 'ExtendedData_PDPTemplate',
    query: ExtendedData_PDPTemplateQuery,
  });

  // Content PDP Queries
  const [aboveProductStoryContent] = useGroqQuery({
    operationName: 'PDP_AboveProductStory',
    query: AboveProductStory_PDPContentQuery,
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });

  const [productStoryAdditionalContentSlotContent] = useGroqQuery({
    operationName: 'PDP_ProductStoryAdditionalContentSlot',
    query: ProductStoryAdditionalContentSlot_PDPContentQuery,
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });

  const [belowProductStoryContent] = useGroqQuery({
    operationName: 'PDP_BelowProductStory',
    query: BelowProductStory_PDPContentQuery,
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });

  const [belowProductGoogleShoppingContent] = useGroqQuery({
    operationName: 'PDP_BelowProductGoogleShopping',
    query: BelowProductGoogleShopping_PDPContentQuery,
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });

  const [extendedDataContent] = useGroqQuery({
    operationName: 'PDP_ExtendedData',
    query: ExtendedData_PDPContentQuery,
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });

  const content = useMemo(() => {
    const fetchingStates = [
      aboveProductStoryTemplateContent.fetching,
      belowProductStoryTemplateContent.fetching,
      belowProductGoogleShoppingTemplateContent.fetching,
      stickyAddToCartTemplateContent.fetching,
      extendedDataTemplateContent.fetching,
      aboveProductStoryContent.fetching,
      productStoryAdditionalContentSlotContent.fetching,
      belowProductStoryContent.fetching,
      belowProductGoogleShoppingContent.fetching,
      extendedDataContent.fetching,
    ];

    if (fetchingStates.some(state => state)) {
      return undefined;
    }

    // Content PDP Template Queries Constants
    const aboveProductStoryTemplate =
      aboveProductStoryTemplateContent.data?.content || {};

    const bellowProductStoryTemplate =
      belowProductStoryTemplateContent.data?.content || {};

    const belowProductGoogleShoppingTemplate =
      belowProductGoogleShoppingTemplateContent.data?.content || {};

    const stickyAddToCartTemplate =
      stickyAddToCartTemplateContent.data?.content || {};

    const extendedDataTemplate =
      extendedDataTemplateContent.data?.content || {};

    // Content PDP Queries Constants
    const aboveProductStory = aboveProductStoryContent.data?.content || {};
    const productStoryAdditionalContentSlot =
      productStoryAdditionalContentSlotContent.data?.content || {};
    const belowProductStory = belowProductStoryContent.data?.content || {};
    const belowProductGoogleShopping =
      belowProductGoogleShoppingContent.data?.content || {};
    const extendedData = extendedDataContent.data?.content || {};

    return {
      // Content PDP Template Data
      ...aboveProductStoryTemplate,
      ...bellowProductStoryTemplate,
      ...belowProductGoogleShoppingTemplate,
      ...stickyAddToCartTemplate,
      ...extendedDataTemplate,

      // Content PDP Data
      ...aboveProductStory,
      ...productStoryAdditionalContentSlot,
      ...belowProductStory,
      ...belowProductGoogleShopping,
      ...extendedData,
    } as ProductDetailsPageTemplateContent & ProductDetailsPageContent;
  }, [
    // Content PDP Dependencies
    aboveProductStoryContent,
    productStoryAdditionalContentSlotContent,
    belowProductStoryContent,
    belowProductGoogleShoppingContent,
    extendedDataContent,

    // Content PDP Template Dependencies
    aboveProductStoryTemplateContent,
    belowProductStoryTemplateContent,
    belowProductGoogleShoppingTemplateContent,
    stickyAddToCartTemplateContent,
    extendedDataTemplateContent,
  ]);

  const [productScoreQuery] = useProductScoreQuery({
    variables: {
      id: desiredProductId,
      includeReviews: true,
    },
    pause: !desiredProductId || !reviewsEnabled || pageLayout !== 'full',
  });

  useEffect(() => {
    // After query has successfully retrieved product data, fire view product
    // tracking event for the newly fetched product.
    if (
      !productQuery.fetching &&
      productQuery.data?.product &&
      !hasSentAnalytics.current
    ) {
      const product = productQuery.data.product;
      trackProductView({ masterId: product.id });
      trackPdp(`${desiredProductId}_${desiredSwatch}`);
      hasSentAnalytics.current = true;
    }
  }, [productQuery.fetching, desiredProductId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    hasSentAnalytics.current = false;
  }, [desiredProductId]);

  // Gets pixlee user generated content associated with the product for display.
  const [ugcResponse] = useUserGeneratedContentQuery({
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });
  const userGeneratedContent = ugcResponse.data?.userGeneratedContentForWeb;

  const pixleeImgs: PixleeImgType[] = useMemo(() => {
    if (!pixleeConfig?.enablePDPGallery || !userGeneratedContent) return [];

    return userGeneratedContent
      .filter(content => content && content.pixleeCdnPhotos?.squareMediumUrl)
      .map(content => ({
        __typename: 'PixleeImg',
        source: content!.source,
        alt: content!.altText || '',
        href: content!.pixleeCdnPhotos!.squareMediumUrl!,
      }));
  }, [pixleeConfig, userGeneratedContent]);

  // Product is orderable if it has a price and is not soldout, comingsoon or
  // outOfStock
  const productIsOrderable = useMemo(() => {
    const displayOutOfStock = variation?.displayOutOfStock;
    const { comingsoon, soldout, soldoutWithRecommender } =
      displayOutOfStock ?? {};
    // 'soldout' is set manually, 'outOfStock' is based on stock levels
    const outOfStock = !variation?.orderable;
    return Boolean(
      variation?.price &&
        !(soldout || soldoutWithRecommender || comingsoon || outOfStock)
    );
  }, [variation?.displayOutOfStock, variation?.price, variation?.orderable]);

  const isAppOnly = !!variation?.isAppExclusive;

  const productDivision: ProductDivision = useMemo(() => {
    return masterProduct?.productDivision === 'Footwear' ? 'Footwear' : 'Other';
  }, [masterProduct?.productDivision]);

  const providerValue = useMemo(
    () => ({
      addToCartError,
      addToCartReturnCode: addToCartReturnCode,
      desiredProductId,
      desiredModel,
      desiredSize,
      desiredSwatch,
      hasLoaded: !productQuery.fetching && !productQuery.stale,
      isAppOnly,
      isFetching,
      isFullLayout: pageLayout === 'full',
      isQueryStale,
      isSimplifiedLayout: pageLayout !== 'full',
      masterProduct,
      pageLayout,
      pixleeImgs,
      productIsOrderable,
      productDivision,
      productQuery,
      lazyProductQuery,
      quantity,
      referrerCategoryId: referrerCategoryId.current,
      setAddToCartError,
      setAddToCartReturnCode: setAddToCartReturnCode,
      setQuantity,
      variation,
      content,
      productScore: productScoreQuery.data?.productScore as Score,
      sizeHasChanged,
      setSizeHasChanged,
      // Content PDP Data
      aboveProductStoryContent,
      productStoryAdditionalContentSlotContent,
      belowProductStoryContent,
      belowProductGoogleShoppingContent,
      extendedDataContent,

      // Content PDP Template Data
      aboveProductStoryTemplateContent,
      belowProductStoryTemplateContent,
      belowProductGoogleShoppingTemplateContent,
      stickyAddToCartTemplateContent,
      extendedDataTemplateContent,
    }),
    [
      addToCartError,
      addToCartReturnCode,
      desiredProductId,
      desiredModel,
      desiredSize,
      desiredSwatch,
      isAppOnly,
      isFetching,
      isQueryStale,
      masterProduct,
      pageLayout,
      pixleeImgs,
      productIsOrderable,
      productDivision,
      productQuery,
      lazyProductQuery,
      quantity,
      referrerCategoryId,
      setAddToCartError,
      setAddToCartReturnCode,
      setQuantity,
      variation,
      content,
      productScoreQuery,
      sizeHasChanged,
      setSizeHasChanged,
      // Content PDP Dependencies
      aboveProductStoryContent,
      productStoryAdditionalContentSlotContent,
      belowProductStoryContent,
      belowProductGoogleShoppingContent,
      extendedDataContent,

      // Content PDP Template Dependencies
      aboveProductStoryTemplateContent,
      belowProductStoryTemplateContent,
      belowProductGoogleShoppingTemplateContent,
      stickyAddToCartTemplateContent,
      extendedDataTemplateContent,
    ]
  );

  return (
    <PdpContext.Provider value={providerValue}>{children}</PdpContext.Provider>
  );
};

// For Product Details Pages
export const usePdp = () => useContext(PdpContext);
