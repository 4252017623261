import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useIsCrawler } from 'hooks/useIsCrawler';
import {
  Maybe,
  Cta,
  useRecommendationsWithDetailsQuery,
} from '__generated__/graphql';
import { extractProductVariants } from 'utils/extractProductVariants';
import { ProductCarousel } from 'ui/content/ProductCarousel';
import { ProductGrid } from 'ui/components/ProductGrid';
import { useTranslate } from 'hooks/useTranslations';
import { useIsServer } from 'hooks/useIsServer';
import { AnalyticsEvents, event } from 'utils/analytics';
import { useGetRecommenderLocation } from 'hooks/useGetRecommenderLocation';

type ProductRecommenderPropsType = {
  categoryId?: string;
  cta?: Maybe<Cta[]>;
  displayRecommendations?: boolean;
  gridTemplateColumnConfig?: GridTemplateColumnConfig;
  header?: Maybe<string>;
  ids?: string[];
  inSideBySide?: boolean;
  productId?: string;
  showAsGrid?: Maybe<boolean>;
  stackProductInfo?: boolean;
  type: string;
  isMiniCart?: boolean;
};

export type GridTemplateColumnConfig = {
  mobile?: any;
  tablet?: any;
  desktop?: any;
};

export const ProductRecommender = ({
  cta,
  gridTemplateColumnConfig,
  header,
  ids,
  inSideBySide,
  showAsGrid,
  stackProductInfo,
  type,
  isMiniCart = false,
}: ProductRecommenderPropsType) => {
  const t = useTranslate();
  const router = useRouter();
  const isCrawler = useIsCrawler();
  const isServer = useIsServer();

  const category = router.query.category as string[];
  const categoryId = Array.isArray(category) ? category.join('-') : category;

  const productId = router.query.id as string;

  const [result] = useRecommendationsWithDetailsQuery({
    variables: {
      pageType: type,
      ids: ids ?? [categoryId, productId].filter(Boolean),
    },
    pause: isServer && !isCrawler,
  });
  const recommendations =
    result.data?.recommendationsWithDetails.recommendations;
  const recommendationsKOP = {
    id: result.data?.recommendationsWithDetails.recommenderId,
    name: result.data?.recommendationsWithDetails.recommenderName,
  };
  // If displaying the recommendations on a PDP - exclude the product shown on a PDP
  const recommendationsWithoutCurrentProduct = recommendations?.filter(
    rec => rec?.masterId !== productId
  );

  const recommenderLocation = useGetRecommenderLocation();
  useEffect(() => {
    if (
      result.data?.recommendationsWithDetails.recommenderName &&
      !showAsGrid
    ) {
      event(AnalyticsEvents.PRODUCT_RECOMMENDER_VIEWED, {
        ecommerce: {
          productRecommenderLocation: recommenderLocation(),
          recommenderName:
            result.data?.recommendationsWithDetails.recommenderName,
        },
      });
    }
  }, [
    recommenderLocation,
    result.data?.recommendationsWithDetails.recommenderName,
    showAsGrid,
  ]);

  if (result.fetching || recommendationsWithoutCurrentProduct?.length === 0)
    return null;

  if (showAsGrid && result.data?.recommendationsWithDetails) {
    return (
      <ProductGrid
        id="product-recommendation-grid"
        header={header || t('youMayAlsoLike')}
        products={extractProductVariants(recommendationsWithoutCurrentProduct)}
        fromRecommender={result.data?.recommendationsWithDetails}
      />
    );
  }

  return (
    <ProductCarousel
      cta={cta}
      gridTemplateColumnConfig={gridTemplateColumnConfig}
      header={header || t('recommendedForYou')}
      id={`ProductRecommender-${type}`}
      inSideBySide={inSideBySide}
      products={extractProductVariants(recommendationsWithoutCurrentProduct)}
      recommendationsKOP={recommendationsKOP}
      fromRecommender={result.data?.recommendationsWithDetails}
      isMiniCart={isMiniCart}
      stackProductInfo={stackProductInfo}
    />
  );
};
