import { FC, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Modal from 'ui/components/Modal';
import CloseButton from 'ui/components/CloseButton';

import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useTranslate } from '../../hooks/useTranslations';
import { AuthFormContextProvider } from '../../hooks/useFormData';

import { ForgotPasswordForm } from './AuthenticateForgotPasswordForm';
import { SignupOTPForm } from './SignupOTPForm';
import { ConfirmOTP } from './ConfirmOTP';
import { LoginWithEmailAndPasswordForm } from './LoginWithEmailAndPassword';
import { LoginOTPForm } from './LoginWithOTP';
import { LoginWithPhoneAndPassword } from './LoginWithPhoneAndPassword';

export type NavigationFunction = (stage: OTPAuthStage) => void;

export enum OTPAuthStage {
  register = 'register',
  login = 'login',
  login_with_email = 'login_with_email',
  login_with_phone_password = 'login_with_phone_password',
  reset_password = 'reset_password',
  confirm_otp = 'confirm_otp',
}

interface AuthenticateTabsProps {
  from?: string | string[];
  action?: OTPAuthStage;
  onSuccess?: () => void;
  resetOnOverlay?: boolean;
  showQuickRegistration?: boolean;
  isInModal?: boolean;
}

const OTPFormElement = (props: {
  navigate: NavigationFunction;
  selectedAction: OTPAuthStage;
  onSuccess?: AuthenticateTabsProps['onSuccess'];
  onForgotPasswordClick: () => void;
}) => {
  const { localizeUrlPath } = useSiteConfig();
  const router = useRouter();

  switch (props.selectedAction) {
    case OTPAuthStage.register:
      return (
        <SignupOTPForm
          onSuccess={() => router.replace(localizeUrlPath('/account'))}
          navigate={props.navigate}
        />
      );
    case OTPAuthStage.confirm_otp:
      return (
        <ConfirmOTP navigate={props.navigate} onSuccess={props.onSuccess} />
      );
    case OTPAuthStage.login_with_email:
      return (
        <LoginWithEmailAndPasswordForm
          onSuccess={props.onSuccess}
          navigate={props.navigate}
          onForgotPasswordClick={props.onForgotPasswordClick}
        />
      );
    case OTPAuthStage.login_with_phone_password:
      return (
        <LoginWithPhoneAndPassword
          onSuccess={props.onSuccess}
          navigate={props.navigate}
          onForgotPasswordClick={props.onForgotPasswordClick}
        />
      );
    case OTPAuthStage.login:
      return <LoginOTPForm navigate={props.navigate} />;
    default:
      return null;
  }
};

const FormTitle = ({ selectedAction }: { selectedAction: OTPAuthStage }) => {
  const t = useTranslate();

  switch (selectedAction) {
    case OTPAuthStage.register:
      return <>{t('register')}</>;
    case OTPAuthStage.confirm_otp:
      return <>{t('enterOTPHeader')}</>;
    case OTPAuthStage.login:
    case OTPAuthStage.login_with_email:
    case OTPAuthStage.login_with_phone_password:
      return <>{t('loginOrRegisterHeader')}</>;
    default:
      return null;
  }
};

export const OTPAuthenticateForm: FC<AuthenticateTabsProps> = ({
  from,
  action = OTPAuthStage.login,
  onSuccess,
  resetOnOverlay,
  showQuickRegistration,
  isInModal,
}) => {
  const t = useTranslate();
  const router = useRouter();
  const { localizeUrlPath } = useSiteConfig();
  const { wishlist, swatch, size } = router.query;

  const [selectedAction, setSelectedAction] = useState<OTPAuthStage>(action);

  useEffect(() => {
    setSelectedAction(action);
  }, [action]);

  const fromSuffix = from ? `&from=${from}` : '';
  const swatchQuery = swatch ? `&swatch=${swatch}` : '';
  const sizeQuery = size ? `&size=${size}` : '';
  const wishlistQuery = wishlist ? `&wishlist=${wishlist}` : '';

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const navigate = useCallback(
    (action: OTPAuthStage) => {
      if (!isInModal) {
        router.replace(
          localizeUrlPath(
            '/account/login?action=' +
              action +
              fromSuffix +
              swatchQuery +
              sizeQuery +
              wishlistQuery
          )
        );
      }

      setSelectedAction(action);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInModal, router, localizeUrlPath]
  );

  const showReset = selectedAction === OTPAuthStage.reset_password;

  const showAuthForm = !showQuickRegistration && (resetOnOverlay || !showReset);

  return (
    <AuthFormContextProvider>
      {showAuthForm && (
        <>
          <h1 className="sr-only">{t('signIn')}</h1>
          <div id="login-container" className="space-y-5">
            <div>
              <div className="text-xl text-center font-bold w-full py-3 mb-8 border-b-2 border-puma-black">
                <FormTitle selectedAction={selectedAction} />
              </div>
              <OTPFormElement
                selectedAction={selectedAction}
                onForgotPasswordClick={() => setShowForgotPasswordModal(true)}
                onSuccess={onSuccess}
                navigate={navigate}
              />
            </div>
          </div>
          {showForgotPasswordModal && (
            <Modal
              className="w-full max-w-md px-8 pb-8 pt-9 relative"
              onClickOutside={() => setShowForgotPasswordModal(false)}
              variant="dark"
              dataTestId="forgotten-password-modal"
            >
              <CloseButton
                className="absolute top-5 right-5 !mt-0"
                onClick={() => setShowForgotPasswordModal(false)}
                dataTestId="close-forgotten-password-modal"
              />
              <ForgotPasswordForm />
            </Modal>
          )}
        </>
      )}
    </AuthFormContextProvider>
  );
};
